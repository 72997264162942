import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectProjects, selectExperiences, selectCertificates, selectInformations, selectLoading } from '../../store/data';
import { adminLogout } from '../../service/service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faProjectDiagram, faBriefcase, faCertificate, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import ProjectsTab from './ProjectsTab';
import ExperiencesTab from './ExperiencesTab';
import CertificatesTab from './CertificatesTab';
import InformationsTab from './InformationsTab';

const Admin = () => {
  const [activeTab, setActiveTab] = useState('projects');
  const projects = useSelector(selectProjects);
  const experiences = useSelector(selectExperiences);
  const certificates = useSelector(selectCertificates);
  const informations = useSelector(selectInformations);
  const loading = useSelector(selectLoading);

  const handleLogout = async () => {
    try {
      await adminLogout();
    } catch (error) {
      console.error('Çıkış hatası:', error);
    }
  };

  return (
    <div className="min-h-screen bg-gray-950 text-white">
      <header className="bg-gray-900 shadow">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center py-6">
            <h1 className="text-3xl font-bold text-white">Admin Paneli</h1>
            <button
              onClick={handleLogout}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
              Çıkış Yap
            </button>
          </div>
        </div>
      </header>

      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-gray-900 shadow overflow-hidden sm:rounded-lg">
          <div className="border-b border-gray-800">
            <nav className="flex -mb-px">
              <button
                onClick={() => setActiveTab('projects')}
                className={`whitespace-nowrap py-4 px-6 border-b-2 font-medium text-sm ${
                  activeTab === 'projects'
                    ? 'border-blue-500 text-blue-400'
                    : 'border-transparent text-gray-200 hover:text-white hover:border-gray-600'
                }`}
              >
                <FontAwesomeIcon icon={faProjectDiagram} className="mr-2" />
                Projeler ({projects.length})
              </button>
              <button
                onClick={() => setActiveTab('experiences')}
                className={`whitespace-nowrap py-4 px-6 border-b-2 font-medium text-sm ${
                  activeTab === 'experiences'
                    ? 'border-blue-500 text-blue-400'
                    : 'border-transparent text-gray-200 hover:text-white hover:border-gray-600'
                }`}
              >
                <FontAwesomeIcon icon={faBriefcase} className="mr-2" />
                Deneyimler ({experiences.length})
              </button>
              <button
                onClick={() => setActiveTab('certificates')}
                className={`whitespace-nowrap py-4 px-6 border-b-2 font-medium text-sm ${
                  activeTab === 'certificates'
                    ? 'border-blue-500 text-blue-400'
                    : 'border-transparent text-gray-200 hover:text-white hover:border-gray-600'
                }`}
              >
                <FontAwesomeIcon icon={faCertificate} className="mr-2" />
                Sertifikalar ({certificates.tr ? certificates.tr.length : 0})
              </button>
              <button
                onClick={() => setActiveTab('informations')}
                className={`whitespace-nowrap py-4 px-6 border-b-2 font-medium text-sm ${
                  activeTab === 'informations'
                    ? 'border-blue-500 text-blue-400'
                    : 'border-transparent text-gray-200 hover:text-white hover:border-gray-600'
                }`}
              >
                <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
                Bilgiler
              </button>
            </nav>
          </div>

          <div className="p-6">
            {loading ? (
              <div className="flex justify-center py-8">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
              </div>
            ) : (
              <>
                {activeTab === 'projects' && <ProjectsTab projects={projects} />}
                {activeTab === 'experiences' && <ExperiencesTab experiences={experiences} />}
                {activeTab === 'certificates' && <CertificatesTab certificates={certificates} />}
                {activeTab === 'informations' && <InformationsTab informations={informations} />}
              </>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

export default Admin; 