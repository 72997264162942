import React, { useState, useEffect } from 'react'
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import Home from './Home';
import Admin from './admin/Admin';
import AdminLogin from './admin/AdminLogin';
import { fetchAllData } from "../service/service";
import { AnimatePresence } from "framer-motion";
import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from '../store/data';

const AnimatedRoutes = () => {
    const location = useLocation();
    const [open, setOpen] = useState(0);
    const isAuthenticated = useSelector(selectIsAuthenticated);

    return (
        <AnimatePresence>
            <Routes location={location} key={location.pathname}>
                <Route path='/' element={<Home open={open}/>}/>
                <Route path='/projects' element={<Navigate to="/" replace />} />
                <Route path='/admin/*' element={
                    isAuthenticated ? <Admin /> : <AdminLogin />
                } />
                <Route path='*' element={<Navigate to="/" replace />} />
            </Routes>
        </AnimatePresence>
    )
}

export default AnimatedRoutes;
