import { createSlice } from '@reduxjs/toolkit';
import informations from './data.json';

const initialState = {
  projects: [],
  experiences: [],
  informations: informations.informations,
  certificates: [],
  loading: false,
  error: null,
  isAuthenticated: false
};

const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    setProjects: (state, action) => {
      state.projects = action.payload;
    },
    setExperiences: (state, action) => {
      state.experiences = action.payload;
    },
    setCertificates: (state, action) => {
      state.certificates = action.payload;
    },
    setInformations: (state, action) => {
      state.informations = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setAuth: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    logout: (state) => {
      state.isAuthenticated = false;
    }
  }
});

export const { 
  setProjects, 
  setExperiences, 
  setCertificates,
  setInformations,
  setLoading,
  setError,
  setAuth,
  logout
} = dataSlice.actions;

export const selectProjects = (state) => state.data.projects;
export const selectExperiences = (state) => state.data.experiences;
export const selectInformations = (state) => state.data.informations;
export const selectCertificates = (state) => state.data.certificates;
export const selectLoading = (state) => state.data.loading;
export const selectError = (state) => state.data.error;
export const selectIsAuthenticated = (state) => state.data.isAuthenticated;

export const getSortedProjects = (state) => {
  return [...state.data.projects].sort((a, b) => a.order - b.order);
};

export default dataSlice.reducer;
