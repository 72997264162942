import React, { useState, useEffect } from 'react';
import { updateInformations } from '../../service/service';
import { FaPencilAlt, FaSave, FaTimes, FaGlobe, FaHome, FaBriefcase, FaProjectDiagram, FaUniversity } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { selectInformations } from '../../store/data';

const InformationsTab = ({ informations }) => {
  const [editedInformations, setEditedInformations] = useState({});
  const [originalInformations, setOriginalInformations] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [editMode, setEditMode] = useState({});
  const [activeTab, setActiveTab] = useState('tr');

  // Redux'tan güncel verileri takip etmek için
  const storeInformations = useSelector(selectInformations);

  useEffect(() => {
    if (informations) {
      setEditedInformations(JSON.parse(JSON.stringify(informations)));
      setOriginalInformations(JSON.parse(JSON.stringify(informations)));
    }
  }, [informations]);

  // Redux store'da bilgiler güncellendiğinde bileşeni güncelle
  useEffect(() => {
    if (storeInformations && Object.keys(storeInformations).length > 0) {
      setEditedInformations(JSON.parse(JSON.stringify(storeInformations)));
      setOriginalInformations(JSON.parse(JSON.stringify(storeInformations)));
    }
  }, [storeInformations]);

  const handleSave = async (lang, section, subsection, field = null) => {
    try {
      setLoading(true);
      
      // Burada gönderilmeden önce derin bir kopyalama yapıyoruz
      const informationsToUpdate = JSON.parse(JSON.stringify(editedInformations));
      
      await updateInformations(informationsToUpdate);
      setOriginalInformations(JSON.parse(JSON.stringify(editedInformations)));
      setMessage('Bilgiler başarıyla güncellendi');
      setMessageType('success');
      
      // Düzenleme modunu kapat
      toggleEditMode(lang, section, subsection, field);
    } catch (error) {
      console.error('Güncelleme hatası detayları:', error);
      setMessage('Bilgiler güncellenirken bir hata oluştu');
      setMessageType('error');
    } finally {
      setLoading(false);
      setTimeout(() => {
        setMessage('');
      }, 3000);
    }
  };

  const toggleEditMode = (lang, section, subsection, field = null) => {
    const key = `${lang}-${section}-${subsection}${field ? `-${field}` : ''}`;
    setEditMode(prev => ({
      ...prev,
      [key]: !prev[key]
    }));
  };

  const cancelEdit = (lang, section, subsection, field = null) => {
    // Değişiklikleri geri al
    setEditedInformations(JSON.parse(JSON.stringify(originalInformations)));
    toggleEditMode(lang, section, subsection, field);
  };

  const handleChange = (lang, section, subsection, field = null, value) => {
    const newEditedInformations = { ...editedInformations };
    
    // Lang yoksa oluştur
    if (!newEditedInformations[lang]) {
      newEditedInformations[lang] = {};
    }
    
    // Section yoksa oluştur
    if (!newEditedInformations[lang][section]) {
      newEditedInformations[lang][section] = {};
    }
    
    if (field) {
      // Subsection yoksa oluştur
      if (!newEditedInformations[lang][section][subsection]) {
        newEditedInformations[lang][section][subsection] = {};
      }
      
      // Özel işleme: Üniversite tarihi için
      if (section === 'experience' && subsection === 'university' && field === 'date') {
        // Hem TR hem EN için aynı tarih değerini kullan
        if (lang === 'tr') {
          // TR için değiştirildiğinde, her iki dil için de aynı değeri kullan
          if (newEditedInformations.en && newEditedInformations.en.experience && 
              newEditedInformations.en.experience.university) {
            newEditedInformations.en.experience.university.date = value;
          }
        } else if (lang === 'en') {
          // EN için değiştirildiğinde, her iki dil için de aynı değeri kullan
          if (newEditedInformations.tr && newEditedInformations.tr.experience && 
              newEditedInformations.tr.experience.university) {
            newEditedInformations.tr.experience.university.date = value;
          }
        }
      }
      
      // Field değerini güncelle
      newEditedInformations[lang][section][subsection][field] = value;
    } else {
      // Subsection değerini doğrudan güncelle
      newEditedInformations[lang][section][subsection] = value;
    }
    
    setEditedInformations(newEditedInformations);
  };

  const isEditMode = (lang, section, subsection, field = null) => {
    const key = `${lang}-${section}-${subsection}${field ? `-${field}` : ''}`;
    return editMode[key];
  };

  const renderField = (lang, section, subsection, field = null, label, isTextarea = false) => {
    let value = '';
    
    try {
      if (field) {
        value = editedInformations[lang]?.[section]?.[subsection]?.[field] || '';
      } else {
        value = editedInformations[lang]?.[section]?.[subsection] || '';
      }
      
      // Eğer value bir obje ise, string'e çevir
      if (typeof value === 'object' && value !== null) {
        if (value.tr && value.en) {
          // TR/EN yapısı varsa aktif dile göre değeri göster
          value = value[lang] || JSON.stringify(value);
        } else {
          // Diğer objeler için string temsiline dönüştür
          value = JSON.stringify(value);
        }
      }
    } catch (error) {
      console.error(`Değer alınırken hata: ${lang}.${section}.${subsection}${field ? `.${field}` : ''}`, error);
      value = '';
    }
    
    const editing = isEditMode(lang, section, subsection, field);

    return (
      <div className={`mb-4 transition-all duration-200 ${editing ? 'bg-gray-700 rounded-lg p-3 shadow-md' : ''}`}>
        <div className="flex justify-between items-center mb-2">
          <label className="block text-sm font-medium text-gray-200 flex items-center gap-2">
            {label}
          </label>
          <div className="flex space-x-2">
            {editing ? (
              <>
                <button
                  type="button"
                  onClick={() => handleSave(lang, section, subsection, field)}
                  className="text-green-400 hover:text-green-300 transition-colors p-1 rounded-full hover:bg-green-900/30"
                  disabled={loading}
                >
                  <FaSave size={16} />
                </button>
                <button
                  type="button"
                  onClick={() => cancelEdit(lang, section, subsection, field)}
                  className="text-red-400 hover:text-red-300 transition-colors p-1 rounded-full hover:bg-red-900/30"
                >
                  <FaTimes size={16} />
                </button>
              </>
            ) : (
              <button
                type="button"
                onClick={() => toggleEditMode(lang, section, subsection, field)}
                className="text-blue-400 hover:text-blue-300 transition-colors p-1 rounded-full hover:bg-blue-900/30"
              >
                <FaPencilAlt size={16} />
              </button>
            )}
          </div>
        </div>
        {editing ? (
          isTextarea ? (
            <textarea
              value={value}
              onChange={(e) => handleChange(lang, section, subsection, field, e.target.value)}
              rows="4"
              className="w-full p-2 border border-blue-500 rounded-md bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-blue-500 transition-shadow"
            ></textarea>
          ) : (
            <input
              type="text"
              value={value}
              onChange={(e) => handleChange(lang, section, subsection, field, e.target.value)}
              className="w-full p-2 border border-blue-500 rounded-md bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-blue-500 transition-shadow"
            />
          )
        ) : (
          <div className="p-2 border border-gray-700 rounded-md bg-gray-800 text-white">
            {value || 'Bilgi girilmemiş'}
          </div>
        )}
      </div>
    );
  };

  if (!editedInformations || !editedInformations.tr || !editedInformations.en) {
    return (
      <div className="flex items-center justify-center h-40">
        <div className="text-white flex items-center">
          <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
          Bilgiler yükleniyor...
        </div>
      </div>
    );
  }

  // Render sections for active tab
  const renderSections = (lang) => {
    return (
      <>
        <div className="bg-gray-800/50 rounded-lg p-4 mb-6 border border-gray-700 shadow-md">
          <div className="flex items-center gap-2 text-blue-400 mb-3">
            <FaHome />
            <h4 className="text-md font-medium text-blue-400">Ana Sayfa</h4>
          </div>
          <div className="pl-2 border-l-2 border-blue-800">
            {renderField(lang, 'hero', 'name', null, 'Adı')}
            {renderField(lang, 'hero', 'role', null, 'İş Unvanı')}
            {renderField(lang, 'hero', 'greeting', null, 'Karşılama')}
          </div>
        </div>
        
        <div className="bg-gray-800/50 rounded-lg p-4 mb-6 border border-gray-700 shadow-md">
          <div className="flex items-center gap-2 text-green-400 mb-3">
            <FaBriefcase />
            <h4 className="text-md font-medium text-green-400">Deneyim</h4>
          </div>
          <div className="pl-2 border-l-2 border-green-800">
            {renderField(lang, 'experience', 'title', null, 'Başlık')}
            
            <div className="mt-3 mb-2 bg-gray-750 rounded p-3">
              <div className="flex items-center gap-2 text-yellow-400 mb-2">
                <FaUniversity />
                <h5 className="text-sm font-medium text-yellow-400">Üniversite</h5>
              </div>
              <div className="pl-2 border-l-2 border-yellow-800">
                {renderField(lang, 'experience', 'university', 'name', 'Üniversite Adı')}
                {renderField(lang, 'experience', 'university', 'department', 'Bölüm')}
                {renderField(lang, 'experience', 'university', 'date', 'Tarih')}
              </div>
            </div>
          </div>
        </div>
        
        <div className="bg-gray-800/50 rounded-lg p-4 mb-6 border border-gray-700 shadow-md">
          <div className="flex items-center gap-2 text-purple-400 mb-3">
            <FaProjectDiagram />
            <h4 className="text-md font-medium text-purple-400">Projeler</h4>
          </div>
          <div className="pl-2 border-l-2 border-purple-800">
            {renderField(lang, 'projects', 'title', null, 'Başlık')}
            {renderField(lang, 'projects', 'description', null, 'Açıklama')}
          </div>
        </div>
      </>
    );
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-semibold text-white">Kişisel Bilgiler</h2>
      </div>

      {message && (
        <div
          className={`p-4 rounded-md mb-4 shadow-md ${
            messageType === 'success' ? 'bg-green-900/40 text-green-300 border border-green-600' : 'bg-red-900/40 text-red-300 border border-red-600'
          }`}
        >
          {messageType === 'success' ? '✅ ' : '❌ '}{message}
        </div>
      )}

      <div className="bg-gray-900 rounded-lg p-4 mb-6 border border-gray-700 shadow-md">
        <div className="flex mb-4 border-b border-gray-700">
          <button
            className={`py-2 px-4 flex items-center gap-2 ${activeTab === 'tr' ? 'text-white border-b-2 border-blue-500' : 'text-gray-400 hover:text-gray-300'}`}
            onClick={() => setActiveTab('tr')}
          >
            <FaGlobe /> Türkçe
          </button>
          <button
            className={`py-2 px-4 flex items-center gap-2 ${activeTab === 'en' ? 'text-white border-b-2 border-blue-500' : 'text-gray-400 hover:text-gray-300'}`}
            onClick={() => setActiveTab('en')}
          >
            <FaGlobe /> İngilizce
          </button>
        </div>

        {renderSections(activeTab)}
      </div>
    </div>
  );
};

export default InformationsTab;
