import React, { useState, useEffect } from 'react';
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import marioHandsDown from "../img/mario-hands-down.svg";
import marioHandUp from "../img/mario-hand-up.svg";

const MarioAnimator = ({ onClick, animationSpeed = 400, showArrow = true }) => {
  const [marioState, setMarioState] = useState("down");
  const [arrowState, setArrowState] = useState("up");
  
  // Animasyon döngüsünü başlat
  useEffect(() => {
    let animationStep = 0;
    let intervalId;
    
    const animateMarioAndArrow = () => {
      // 3 adımlı animasyon dizisi
      switch (animationStep) {
        case 0:
          // 1. Adım: Mario aşağıda, ok yukarıda (başlangıç)
          setMarioState("down");
          setArrowState("up");
          break;
        case 1:
          // 2. Adım: Mario ve ok birlikte aşağı iner
          setMarioState("down");
          setArrowState("down");
          break;
        case 2:
          // 3. Adım: Mario zıplar, eli havada, ok yukarı çıkar
          setMarioState("up");
          setArrowState("up");
          break;
      }
      
      // Sonraki adıma geç
      animationStep = (animationStep + 1) % 3;
    };
    
    // Sürekli animasyon için interval kullan
    intervalId = setInterval(animateMarioAndArrow, animationSpeed);
    
    return () => {
      clearInterval(intervalId);
    };
  }, [animationSpeed]);
  
  return (
    <div className="relative flex flex-col items-center cursor-pointer" onClick={onClick}>
      {/* Mario - İki farklı görüntü arasında geçiş */}
      <motion.img
        src={marioState === "down" ? marioHandsDown : marioHandUp}
        alt="Mario"
        className="w-8 h-8 z-10"
        animate={{ 
          y: marioState === "down" ? (arrowState === "down" ? 5 : 0) : -22,
          filter: marioState === "down" 
            ? "drop-shadow(0 0 2px rgba(255,255,255,0.7))" 
            : "drop-shadow(0 0 3px rgba(255,255,255,0.9))"
        }}
        transition={{ 
          duration: 0.3,
          ease: marioState === "down" ? "easeIn" : [0.2, 0.6, 0.4, 1]
        }}
      />
      
      {/* Arrow */}
      {showArrow && (
        <motion.div 
          className="mt-2"
          animate={{ 
            y: arrowState === "down" ? 12 : 0,
            scale: 1
          }}
          transition={{
            duration: 0.3,
            ease: "easeInOut"
          }}
        >
          <FontAwesomeIcon 
            icon={faArrowDown} 
            className="text-white text-2xl"
          />
        </motion.div>
      )}
    </div>
  );
};

export default MarioAnimator;