import { get, ref, set, update, push, remove } from "firebase/database";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { database, auth } from "../firebase";
import store from "../store/index";
import { 
  setProjects,
  setExperiences,
  setCertificates,
  setInformations,
  setLoading,
  setError,
  setAuth,
  login,
  logout
} from "../store/data";

export const fetchAllData = async () => {
  try {
    store.dispatch(setLoading(true));
    
    // Fetch projects
    const projectsRef = ref(database, '/projects');
    const projectsSnapshot = await get(projectsRef);
    if (projectsSnapshot.exists()) {
      const projects = Object.entries(projectsSnapshot.val()).map(([id, project]) => ({
        ...project,
        id: Number(id)
      }));
      store.dispatch(setProjects(projects));
    }

    // Fetch experiences
    const experiencesRef = ref(database, '/experiences');
    const experiencesSnapshot = await get(experiencesRef);
    if (experiencesSnapshot.exists()) {
      store.dispatch(setExperiences(experiencesSnapshot.val()));
    }

    // Fetch certificates
    const certificatesRef = ref(database, '/certificates');
    const certificatesSnapshot = await get(certificatesRef);
    if (certificatesSnapshot.exists()) {
      store.dispatch(setCertificates(certificatesSnapshot.val()));
    }
    
    // Fetch informations
    const informationsRef = ref(database, '/informations');
    const informationsSnapshot = await get(informationsRef);
    if (informationsSnapshot.exists()) {
      store.dispatch(setInformations(informationsSnapshot.val()));
    }

  } catch (error) {
    store.dispatch(setError(error.message));
    throw error;
  } finally {
    store.dispatch(setLoading(false));
  }
};

// Admin kimlik doğrulama işlemleri
export const adminLogin = async (email, password) => {
  try {
    store.dispatch(setLoading(true));
    store.dispatch(setError(null));
    
    // Firebase ile oturum açma
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    
    store.dispatch(setAuth(true));
    return true;
  } catch (error) {
    // Hata mesajlarını kullanıcı dostu hale getirme
    let errorMessage = "Giriş yapılırken bir hata oluştu.";
    if (error.code === "auth/invalid-credential" || 
        error.code === "auth/user-not-found" || 
        error.code === "auth/wrong-password") {
      errorMessage = "E-posta veya şifre hatalı.";
    } else if (error.code === "auth/too-many-requests") {
      errorMessage = "Çok fazla başarısız giriş denemesi. Lütfen daha sonra tekrar deneyin.";
    } else if (error.code === "auth/network-request-failed") {
      errorMessage = "Ağ hatası. İnternet bağlantınızı kontrol edin.";
    }
    
    store.dispatch(setError(errorMessage));
    return false;
  } finally {
    store.dispatch(setLoading(false));
  }
};

export const adminLogout = async () => {
  try {
    store.dispatch(setLoading(true));
    await signOut(auth);
    store.dispatch(logout());
    return true;
  } catch (error) {
    store.dispatch(setError("Çıkış yapılırken bir hata oluştu."));
    return false;
  } finally {
    store.dispatch(setLoading(false));
  }
};

// CRUD işlemleri
// Proje işlemleri
export const addProject = async (project) => {
  try {
    store.dispatch(setLoading(true));
    const projectsRef = ref(database, '/projects');
    const projectsSnapshot = await get(projectsRef);
    
    // En yüksek ID'yi bulma ve bir sonraki ID'yi kullanma
    let nextId = "0";
    if (projectsSnapshot.exists()) {
      const projects = projectsSnapshot.val();
      const ids = Object.keys(projects).map(id => parseInt(id)).filter(id => !isNaN(id));
      
      if (ids.length > 0) {
        nextId = Math.max(...ids) + 1 + "";
      }
    }
    
    // Yeni projeyi belirli ID ile ekleme
    const newProjectRef = ref(database, `/projects/${nextId}`);
    await set(newProjectRef, project);
    await fetchAllData();
  } catch (error) {
    store.dispatch(setError(error.message));
    throw error;
  } finally {
    store.dispatch(setLoading(false));
  }
};

export const updateProject = async (id, project) => {
  try {
    store.dispatch(setLoading(true));
    const projectRef = ref(database, `/projects/${id}`);
    await update(projectRef, project);
    await fetchAllData();
  } catch (error) {
    store.dispatch(setError(error.message));
    throw error;
  } finally {
    store.dispatch(setLoading(false));
  }
};

export const deleteProject = async (id) => {
  try {
    store.dispatch(setLoading(true));
    const projectRef = ref(database, `/projects/${id}`);
    await remove(projectRef);
    await fetchAllData();
  } catch (error) {
    store.dispatch(setError(error.message));
    throw error;
  } finally {
    store.dispatch(setLoading(false));
  }
};

// Deneyim işlemleri
export const addExperience = async (experience) => {
  try {
    store.dispatch(setLoading(true));
    const experiencesRef = ref(database, '/experiences');
    const experiencesSnapshot = await get(experiencesRef);
    
    // En yüksek ID'yi bulma ve bir sonraki ID'yi kullanma
    let nextId = "0";
    if (experiencesSnapshot.exists()) {
      const experiences = experiencesSnapshot.val();
      const ids = Object.keys(experiences).map(id => parseInt(id)).filter(id => !isNaN(id));
      
      if (ids.length > 0) {
        nextId = Math.max(...ids) + 1 + "";
      }
    }
    
    // Yeni deneyimi belirli ID ile ekleme
    const newExperienceRef = ref(database, `/experiences/${nextId}`);
    await set(newExperienceRef, experience);
    await fetchAllData();
  } catch (error) {
    store.dispatch(setError(error.message));
    throw error;
  } finally {
    store.dispatch(setLoading(false));
  }
};

export const updateExperience = async (id, experience) => {
  try {
    store.dispatch(setLoading(true));
    const experienceRef = ref(database, `/experiences/${id}`);
    await update(experienceRef, experience);
    await fetchAllData();
  } catch (error) {
    store.dispatch(setError(error.message));
    throw error;
  } finally {
    store.dispatch(setLoading(false));
  }
};

export const deleteExperience = async (id) => {
  try {
    store.dispatch(setLoading(true));
    const experienceRef = ref(database, `/experiences/${id}`);
    await remove(experienceRef);
    await fetchAllData();
  } catch (error) {
    store.dispatch(setError(error.message));
    throw error;
  } finally {
    store.dispatch(setLoading(false));
  }
};

// Sertifika işlemleri
export const addCertificate = async (language, certificate) => {
  try {
    store.dispatch(setLoading(true));
    const certificatesRef = ref(database, `/certificates/${language}`);
    const certificatesSnapshot = await get(certificatesRef);
    let certificates = [];
    if (certificatesSnapshot.exists()) {
      certificates = certificatesSnapshot.val();
    }
    certificates.push(certificate);
    await set(certificatesRef, certificates);
    await fetchAllData();
  } catch (error) {
    store.dispatch(setError(error.message));
    throw error;
  } finally {
    store.dispatch(setLoading(false));
  }
};

export const updateCertificate = async (language, index, certificate) => {
  try {
    store.dispatch(setLoading(true));
    const certificatesRef = ref(database, `/certificates/${language}`);
    const certificatesSnapshot = await get(certificatesRef);
    if (certificatesSnapshot.exists()) {
      const certificates = certificatesSnapshot.val();
      certificates[index] = certificate;
      await set(certificatesRef, certificates);
      await fetchAllData();
    }
  } catch (error) {
    store.dispatch(setError(error.message));
    throw error;
  } finally {
    store.dispatch(setLoading(false));
  }
};

export const deleteCertificate = async (language, index) => {
  try {
    store.dispatch(setLoading(true));
    const certificatesRef = ref(database, `/certificates/${language}`);
    const certificatesSnapshot = await get(certificatesRef);
    if (certificatesSnapshot.exists()) {
      const certificates = certificatesSnapshot.val();
      certificates.splice(index, 1);
      await set(certificatesRef, certificates);
      await fetchAllData();
    }
  } catch (error) {
    store.dispatch(setError(error.message));
    throw error;
  } finally {
    store.dispatch(setLoading(false));
  }
};

export const updateInformations = async (informations) => {
  try {
    store.dispatch(setLoading(true));
    const informationsRef = ref(database, '/informations');
    await set(informationsRef, informations);
    await fetchAllData();
  } catch (error) {
    store.dispatch(setError(error.message));
    throw error;
  } finally {
    store.dispatch(setLoading(false));
  }
};
