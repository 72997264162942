import React, { useState, useEffect, useRef, useCallback } from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faAngular, faReact, faNode, faDocker, faPython, faJs, faHtml5, faCss3Alt, faSass, faNodeJs, faJava, faPhp, faWordpress, faBootstrap, faLinux, faWindows, faApple, faGitlab, faBitbucket, faNpm, faLinkedin, faMedium, faCodepen, faStackOverflow, faTwitter, faFacebook, faInstagram, faYoutube, faVuejs } from "@fortawesome/free-brands-svg-icons";
import { faFile, faCode, faGlobe, faPlay, faDownload } from "@fortawesome/free-solid-svg-icons";
import sql from '../img/sql.png';
import net from '../img/net.png';
import kafka from '../img/kafka.png';
import defaultProjectImage from '../img/default-project.png';

// Global değişkenler
const DEFAULT_PROJECT_IMAGE = defaultProjectImage;

const iconMap = {
    // Brand ikonları
    'github': faGithub,
    'angular': faAngular,
    'react': faReact,
    'node': faNode,
    'docker': faDocker,
    'python': faPython,
    'js': faJs,
    'vuejs': faVuejs,
    'html5': faHtml5,
    'css3-alt': faCss3Alt,
    'sass': faSass,
    'node-js': faNodeJs,
    'java': faJava,
    'php': faPhp,
    'wordpress': faWordpress,
    'bootstrap': faBootstrap,
    'linux': faLinux,
    'windows': faWindows,
    'apple': faApple,
    'gitlab': faGitlab,
    'bitbucket': faBitbucket,
    'npm': faNpm,
    'linkedin': faLinkedin,
    'medium': faMedium,
    'codepen': faCodepen,
    'stack-overflow': faStackOverflow,
    'twitter': faTwitter,
    'facebook': faFacebook,
    'instagram': faInstagram,
    'youtube': faYoutube,
    
    // Solid ikonları
    'globe': faGlobe,
    'play': faPlay,
    'download': faDownload,
    'file': faFile,
    'code': faCode,
    
    // Eski notasyon için geriye dönük uyumluluk
    'faGithub': faGithub,
    'faAngular': faAngular,
    'faReact': faReact,
    'faNode': faNode,
    'faDocker': faDocker,
    'faPython': faPython,
    'faFile': faFile,
    
    // Resimler
    'sql': sql,
    'net': net,
    'kafka': kafka
};

const ProjeItem = ({ item, isActive = true, language = 'tr' }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [currentImage, setCurrentImage] = useState(DEFAULT_PROJECT_IMAGE);
    const [imageError, setImageError] = useState(false);
    const [truncatedText, setTruncatedText] = useState('');
    const textRef = useRef(null);
    const resizeHandlerRef = useRef(null);
    const hasMultipleImages = Array.isArray(item.img_Url) && item.img_Url.length > 1;

    // Debug için item'ı kontrol et
    useEffect(() => {
        // console.log("Item data:", item);
        // console.log("Image URLs:", item.img_Url);
    }, [item]);

    // Metin kırpma fonksiyonu
    const truncateText = useCallback(() => {
        // textRef.current'in null olmadığından emin ol
        if (!textRef.current) {
            return;
        }
        
        const originalText = item.text?.[language] || item.text || '';
        const maxHeight = 150; // Maksimum yükseklik
        let text = originalText;
        
        try {
            // Önce orijinal metni ayarla ve yüksekliğini ölç
            textRef.current.textContent = originalText;
            
            // Metin uzunsa kısaltma işlemi yap
            if (textRef.current.scrollHeight > maxHeight) {
                let low = 0;
                let high = originalText.length;
                let mid;
                
                while (low <= high) {
                    mid = Math.floor((low + high) / 2);
                    text = originalText.slice(0, mid) + '...';
                    textRef.current.textContent = text;
                    
                    if (textRef.current.scrollHeight <= maxHeight) {
                        low = mid + 1;
                    } else {
                        high = mid - 1;
                    }
                }
                
                text = originalText.slice(0, high) + '...';
            } else {
                text = originalText;
            }
            
            setTruncatedText(text);
        } catch (error) {
            // Herhangi bir hata durumunda orijinal metni kullan
            console.error("Text truncation error:", error);
            setTruncatedText(originalText);
        }
    }, [item.text, language]);

    // İlk render ve metin değiştiğinde truncate işlemini yap
    useEffect(() => {
        // Bir sonraki render çevriminde truncate işlemini çalıştır
        // Bu, ref'in doğru şekilde atanmasını sağlar
        const timer = setTimeout(() => {
            if (textRef.current) {
                truncateText();
            }
        }, 0);
        
        return () => clearTimeout(timer);
    }, [truncateText]);

    // Resize event listener'ını yönet
    useEffect(() => {
        // Resize handler'ı oluştur
        const handleResize = () => {
            // DOM'da hala var mı kontrol et
            if (document.body.contains(textRef.current)) {
                truncateText();
            }
        };
        
        // Handler'ı ref'e kaydet (temizlik için)
        resizeHandlerRef.current = handleResize;
        
        // Event listener'ı ekle
        window.addEventListener('resize', handleResize);
        
        // Temizlik fonksiyonu
        return () => {
            window.removeEventListener('resize', handleResize);
            // Referansı temizle
            resizeHandlerRef.current = null;
        };
    }, [truncateText]);

    // Bileşen unmount edilmeden önce tüm statik referansları temizle
    useEffect(() => {
        return () => {
            // Tüm event listener'ları temizle
            if (resizeHandlerRef.current) {
                window.removeEventListener('resize', resizeHandlerRef.current);
                resizeHandlerRef.current = null;
            }
        };
    }, []);

    useEffect(() => {
        let isMounted = true;
        setImageError(false);

        const loadImage = async (url) => {
            if (!url || url === "none.png" || typeof url !== 'string') {
                if (isMounted) {
                    setCurrentImage(DEFAULT_PROJECT_IMAGE);
                    setImageError(true);
                }
                return;
            }

            try {
                const img = new Image();
                img.src = url;
                await new Promise((resolve, reject) => {
                    img.onload = resolve;
                    img.onerror = reject;
                });
                
                if (isMounted) {
                    setCurrentImage(url);
                    setImageError(false);
                }
            } catch (error) {
                // console.error("Image load error for URL:", url);
                if (isMounted) {
                    setImageError(true);
                    setCurrentImage(DEFAULT_PROJECT_IMAGE);
                }
            }
        };

        const imageUrl = item.img_Url?.[currentImageIndex];
        // console.log("Trying to load image URL:", imageUrl);
        // console.log("Default project image:", DEFAULT_PROJECT_IMAGE);
        loadImage(imageUrl);

        return () => {
            isMounted = false;
        };
    }, [currentImageIndex, item.img_Url]);

    // Otomatik resim geçişi için - sadece aktif kart için
    useEffect(() => {
        if (isActive && hasMultipleImages) {
            const timer = setInterval(() => {
                setCurrentImageIndex((prev) => (prev + 1) % item.img_Url.length);
            }, 3000);

            return () => clearInterval(timer);
        }
    }, [isActive, hasMultipleImages, item.img_Url?.length]);

    // Kart değiştiğinde resim indexini sıfırla
    useEffect(() => {
        setCurrentImageIndex(0);
        setCurrentImage(DEFAULT_PROJECT_IMAGE);
    }, [item.title]);

    // Icon kontrolü
    const getIcon = (iconName) => {
        if (!iconName) return faCode;
        return iconMap[iconName] || faCode;
    };

    return (
        <motion.div 
            className="flex h-full bg-stone-900"
            initial={false}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
        >
            <div className="w-1/2 h-full p-8 flex flex-col justify-between">
                <div>
                    <div className="flex flex-wrap gap-4 mb-6">
                        {item.top_Link?.map((tech, index) => (
                            <div key={index} className="flex items-center gap-2 bg-stone-800 px-3 py-1.5 rounded-full">
                                {iconMap[tech.img_name] ? (
                                    <FontAwesomeIcon 
                                        icon={getIcon(tech.img_name)} 
                                        className="w-5 h-5 text-white"
                                    />
                                ) : (
                                    <img 
                                        src={iconMap[tech.img_name] || ''}
                                        alt={tech.link || ''}
                                        className="w-5 h-5"
                                        onError={(e) => {
                                            e.target.style.display = 'none';
                                        }}
                                    />
                                )}
                                <span className="text-white text-sm">{tech.link}</span>
                            </div>
                        ))}
                    </div>

                    <h3 className="text-3xl font-bold text-white mb-6">{item.title?.[language] || item.title}</h3>
                    <p 
                        ref={textRef}
                        className="text-gray-300 text-lg leading-relaxed max-h-[150px] overflow-hidden"
                    >
                        {truncatedText || (item.text?.[language] || item.text)}
                    </p>
                </div>

                <div className="flex gap-4 mt-6">
                    {item.bottom_Link?.map((link, index) => (
                        <a
                            key={index}
                            href={link.link}
                            target="_blank"
                            rel="noreferrer"
                            className="flex items-center gap-2 px-4 py-2 bg-stone-800 hover:bg-stone-700 rounded-lg transition-colors"
                        >
                            <FontAwesomeIcon 
                                icon={getIcon(link.img_name)} 
                                className="text-white w-5 h-5"
                            />
                            <span className="text-white font-medium">
                                {link.pdf ? (language === 'tr' ? "Dokümantasyon" : "Documentation") : "GitHub"}
                            </span>
                        </a>
                    ))}
                </div>
            </div>

            <div className="w-1/2 h-full relative overflow-hidden bg-stone-800">
                <motion.img 
                    key={currentImageIndex}
                    src={typeof currentImage === 'string' ? currentImage : DEFAULT_PROJECT_IMAGE}
                    alt={item.title || 'Proje Görseli'}
                    className="w-full h-full object-contain p-4"
                    initial={{ opacity: 0, scale: 0.95 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.4 }}
                    onError={() => {
                        // console.error("Image load error, using default image");
                        setImageError(true);
                        setCurrentImage(DEFAULT_PROJECT_IMAGE);
                    }}
                />
                
                {isActive && hasMultipleImages && !imageError && (
                    <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex gap-2">
                        {item.img_Url?.map((_, index) => (
                            <button
                                key={index}
                                onClick={() => setCurrentImageIndex(index)}
                                className={`w-3 h-3 rounded-full transition-all ${
                                    index === currentImageIndex 
                                        ? 'bg-blue-500 scale-110' 
                                        : 'bg-gray-400 hover:bg-gray-300'
                                }`}
                                aria-label={`Go to image ${index + 1}`}
                            />
                        ))}
                    </div>
                )}
            </div>
        </motion.div>
    );
};

export default ProjeItem;
