import React, { useState } from 'react';
import { addCertificate, updateCertificate, deleteCertificate } from '../../service/service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faPlus, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';

const emptyCertificate = {
  title: { en: '', tr: '' },
  date: '',
  issuer: { en: '', tr: '' }
};

const languages = [
  { key: 'tr', label: 'Türkçe' },
  { key: 'en', label: 'İngilizce' }
];

const CertificatesTab = ({ certificates }) => {
  const [activeLanguage, setActiveLanguage] = useState('tr');
  const [editingCertificate, setEditingCertificate] = useState(null);
  const [formData, setFormData] = useState({ ...emptyCertificate });
  const [showForm, setShowForm] = useState(false);
  const [editingId, setEditingId] = useState(null);

  const handleEdit = (certificate, index) => {
    // Sertifikaları düzenlerken her iki dildeki aynı index'teki veriyi al
    const trCertificate = certificates.tr[index] || {};
    const enCertificate = certificates.en[index] || {};
    
    // Form verilerini birleştir
    const combinedCertificate = {
      title: {
        tr: trCertificate.title?.tr || trCertificate.name || '',
        en: enCertificate.title?.en || enCertificate.name || ''
      },
      date: trCertificate.date || enCertificate.date || '',
      issuer: {
        tr: trCertificate.issuer || '',
        en: enCertificate.issuer || ''
      }
    };
    
    setEditingCertificate(index);
    setFormData(combinedCertificate);
    setShowForm(true);
    setEditingId(index);
  };

  const handleNew = () => {
    setFormData({ ...emptyCertificate });
    setShowForm(true);
    setEditingId(null);
  };

  const handleCancel = () => {
    setShowForm(false);
    setEditingId(null);
  };

  const handleDelete = async (index) => {
    if (window.confirm('Bu sertifikayı silmek istediğinizden emin misiniz?')) {
      try {
        // Her iki dilde de aynı indeksteki sertifikayı sil
        await Promise.all([
          deleteCertificate('tr', index),
          deleteCertificate('en', index)
        ]);
      } catch (error) {
        console.error('Silme hatası:', error);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    if (name.includes('.')) {
      // Nokta içeren alanlar için (örn: title.tr, issuer.en)
      const [field, lang] = name.split('.');
      setFormData({
        ...formData,
        [field]: {
          ...(formData[field] || {}),
          [lang]: value
        }
      });
    } else {
      // Düz alanlar için (date)
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const trData = {
        name: formData.title.tr,
        title: { tr: formData.title.tr, en: formData.title.en },
        date: formData.date,
        issuer: formData.issuer.tr
      };
      
      const enData = {
        name: formData.title.en,
        title: { tr: formData.title.tr, en: formData.title.en },
        date: formData.date,
        issuer: formData.issuer.en
      };
      
      if (editingId !== null) {
        // Her iki dildeki sertifikayı da güncelle
        await Promise.all([
          updateCertificate('tr', editingId, trData),
          updateCertificate('en', editingId, enData)
        ]);
      } else {
        // Her iki dile de yeni sertifika ekle
        await Promise.all([
          addCertificate('tr', trData),
          addCertificate('en', enData)
        ]);
      }
      
      setShowForm(false);
      setEditingId(null);
    } catch (error) {
      console.error('Kaydetme hatası:', error);
    }
  };

  const currentCertificates = certificates && certificates[activeLanguage] ? certificates[activeLanguage] : [];

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-semibold text-white">Sertifikalar</h2>
        {!showForm && (
          <button
            onClick={handleNew}
            className="inline-flex items-center px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700"
          >
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            Yeni Sertifika
          </button>
        )}
      </div>

      <div className="mb-6">
        <div className="flex border-b border-gray-200">
          {languages.map((lang) => (
            <button
              key={lang.key}
              onClick={() => setActiveLanguage(lang.key)}
              className={`py-2 px-4 text-sm font-medium ${
                activeLanguage === lang.key
                  ? 'border-b-2 border-blue-500 text-blue-600'
                  : 'text-gray-500 hover:text-gray-700'
              }`}
            >
              {lang.label}
            </button>
          ))}
        </div>
      </div>

      {showForm && (
        <form onSubmit={handleSubmit} className="bg-gray-800 p-6 rounded-lg mb-6 border border-gray-700">
          <h3 className="text-lg font-medium mb-4 text-white">
            {editingId !== null ? 'Sertifikayı Düzenle' : 'Yeni Sertifika Ekle'}
          </h3>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div>
              <label className="block text-sm font-medium text-gray-200 mb-1">
                Başlık (TR)
              </label>
              <input
                type="text"
                name="title.tr"
                value={formData.title.tr}
                onChange={handleChange}
                className="w-full p-2 border border-gray-600 rounded-md bg-gray-700 text-white"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-200 mb-1">
                Başlık (EN)
              </label>
              <input
                type="text"
                name="title.en"
                value={formData.title.en}
                onChange={handleChange}
                className="w-full p-2 border border-gray-600 rounded-md bg-gray-700 text-white"
              />
            </div>
          </div>
          
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-200 mb-1">
              Tarih
            </label>
            <input
              type="text"
              name="date"
              value={formData.date}
              onChange={handleChange}
              className="w-full p-2 border border-gray-600 rounded-md bg-gray-700 text-white"
              placeholder="Örn: 04.03.2023 - 27.03.2023"
            />
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div>
              <label className="block text-sm font-medium text-gray-200 mb-1">
                Açıklama (TR)
              </label>
              <textarea
                name="issuer.tr"
                value={formData.issuer.tr}
                onChange={handleChange}
                rows="3"
                className="w-full p-2 border border-gray-600 rounded-md bg-gray-700 text-white"
              ></textarea>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-200 mb-1">
                Açıklama (EN)
              </label>
              <textarea
                name="issuer.en"
                value={formData.issuer.en}
                onChange={handleChange}
                rows="3"
                className="w-full p-2 border border-gray-600 rounded-md bg-gray-700 text-white"
              ></textarea>
            </div>
          </div>
          
          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={handleCancel}
              className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400"
            >
              İptal
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
            >
              <FontAwesomeIcon icon={faSave} className="mr-2" />
              Kaydet
            </button>
          </div>
        </form>
      )}

      <div className="overflow-x-auto">
        <table className="min-w-full bg-gray-800">
          <thead className="bg-gray-700">
            <tr>
              <th className="py-3 px-4 text-left text-sm font-medium text-gray-200 uppercase tracking-wider">Sertifika Adı</th>
              <th className="py-3 px-4 text-left text-sm font-medium text-gray-200 uppercase tracking-wider">Tarih</th>
              <th className="py-3 px-4 text-left text-sm font-medium text-gray-200 uppercase tracking-wider">Açıklama</th>
              <th className="py-3 px-4 text-left text-sm font-medium text-gray-200 uppercase tracking-wider">İşlemler</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-700">
            {currentCertificates && currentCertificates.map((certificate, index) => (
              <tr key={index} className="hover:bg-gray-700">
                <td className="py-3 px-4 text-sm">{certificate.title?.[activeLanguage] || certificate.name || ''}</td>
                <td className="py-3 px-4 text-sm">{certificate.date || ''}</td>
                <td className="py-3 px-4 text-sm max-w-xs truncate">{certificate.issuer || ''}</td>
                <td className="py-3 px-4 text-sm space-x-2">
                  <button
                    onClick={() => handleEdit(certificate, index)}
                    className="inline-flex items-center p-1.5 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </button>
                  <button
                    onClick={() => handleDelete(index)}
                    className="inline-flex items-center p-1.5 bg-red-600 text-white rounded-md hover:bg-red-700"
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </td>
              </tr>
            ))}
            {(!currentCertificates || currentCertificates.length === 0) && (
              <tr>
                <td colSpan="4" className="py-4 text-center text-gray-500">
                  Sertifika bulunamadı.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CertificatesTab; 