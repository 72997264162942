import React, { useState } from 'react';
import { addProject, updateProject, deleteProject } from '../../service/service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faPlus, faSave, faTimes, faArrowUp, faArrowDown, faGlobe, faPlay, faDownload } from '@fortawesome/free-solid-svg-icons';
import { faReact, faAngular, faVuejs, faJs, faHtml5, faCss3Alt, faSass, faNodeJs, faPython, faJava, faPhp, faWordpress, faBootstrap, faLinux, faWindows, faApple, faGithub, faGitlab, faBitbucket, faNpm, faLinkedin, faMedium, faCodepen, faStackOverflow, faTwitter, faFacebook, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';

const emptyProject = {
  title: { en: '', tr: '' },
  text: { en: '', tr: '' },
  img_Url: [],
  top_Link: [],
  bottom_Link: [],
  order: 0,
  positionOption: 'end' // Varsayılan olarak sona ekle
};

const ProjectsTab = ({ projects }) => {
  const [editingProject, setEditingProject] = useState(null);
  const [newProject, setNewProject] = useState(null);
  const [formData, setFormData] = useState({ ...emptyProject });
  const [sortedProjects, setSortedProjects] = useState([]);

  React.useEffect(() => {
    if (projects) {
      setSortedProjects([...projects].sort((a, b) => a.order - b.order));
    }
  }, [projects]);

  const handleEdit = (project) => {
    setEditingProject(project.id);
    setFormData({ ...project });
    setNewProject(null);
  };

  const handleNew = () => {
    setNewProject(true);
    setEditingProject(null);
    setFormData({ 
      ...emptyProject,
      positionOption: 'end' // Varsayılan olarak sona ekle
    });
  };

  const handleCancel = () => {
    setEditingProject(null);
    setNewProject(null);
  };

  const handleDelete = async (id) => {
    if (window.confirm('Bu projeyi silmek istediğinizden emin misiniz?')) {
      try {
        await deleteProject(id);
      } catch (error) {
        console.error('Silme hatası:', error);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.includes('.')) {
      const [field, lang] = name.split('.');
      setFormData({
        ...formData,
        [field]: {
          ...formData[field],
          [lang]: value
        }
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const handleArrayChange = (field, index, value) => {
    const newArray = [...formData[field]];
    if (value === null) {
      // Sil
      newArray.splice(index, 1);
    } else if (index >= newArray.length) {
      // Ekle
      newArray.push(value);
    } else {
      // Güncelle
      newArray[index] = value;
    }
    setFormData({
      ...formData,
      [field]: newArray
    });
  };

  const handleLinkChange = (field, index, key, value) => {
    const newLinks = [...formData[field]];
    if (newLinks[index]) {
      newLinks[index] = {
        ...newLinks[index],
        [key]: value
      };
    } else {
      newLinks[index] = { [key]: value };
    }
    setFormData({
      ...formData,
      [field]: newLinks
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedFormData = { ...formData };
      
      // Sıralama konumunu ayarla
      if (formData.positionOption === 'start') {
        // En başa ekle - diğer tüm projelerin sırasını bir artır
        updatedFormData.order = 1;
        
        if (newProject) {
          // Diğer projelerin sırasını güncelle
          for (const project of sortedProjects) {
            await updateProject(project.id, {
              ...project,
              order: project.order + 1
            });
          }
        }
      } else if (formData.positionOption === 'end') {
        // En sona ekle
        updatedFormData.order = sortedProjects.length > 0 
          ? sortedProjects[sortedProjects.length - 1].order + 1 
          : 1;
      }
      
      if (newProject) {
        await addProject(updatedFormData);
      } else if (editingProject !== null) {
        await updateProject(editingProject, updatedFormData);
      }
      setEditingProject(null);
      setNewProject(null);
    } catch (error) {
      console.error('Kaydetme hatası:', error);
    }
  };

  const handleMoveProject = async (projectId, direction) => {
    const currentIndex = sortedProjects.findIndex(p => p.id === projectId);
    if (
      (direction === 'up' && currentIndex === 0) || 
      (direction === 'down' && currentIndex === sortedProjects.length - 1)
    ) {
      return; // İlk ya da son elemanda sınırı aşmayı engelle
    }

    const targetIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;
    const newProjects = [...sortedProjects];
    
    // Sıra değerlerini değiştir
    const currentProject = { ...newProjects[currentIndex] };
    const targetProject = { ...newProjects[targetIndex] };
    
    const tempOrder = currentProject.order;
    currentProject.order = targetProject.order;
    targetProject.order = tempOrder;
    
    // Projeler listesini güncelle
    newProjects[currentIndex] = targetProject;
    newProjects[targetIndex] = currentProject;
    
    // State'i güncelle
    setSortedProjects(newProjects);
    
    // Veritabanını güncelle
    try {
      await updateProject(currentProject.id, currentProject);
      await updateProject(targetProject.id, targetProject);
    } catch (error) {
      console.error('Sıralama güncellenirken hata oluştu:', error);
      // Hata durumunda eski listeyi geri yükle
      setSortedProjects([...sortedProjects]);
    }
  };

  // Hazır ikon listeleri
  const technologyIcons = [
    { name: 'React', icon: 'react' },
    { name: 'Angular', icon: 'angular' },
    { name: 'Vue.js', icon: 'vuejs' },
    { name: 'JavaScript', icon: 'js' },
    { name: 'TypeScript', icon: 'ts' },
    { name: 'HTML5', icon: 'html5' },
    { name: 'CSS3', icon: 'css3-alt' },
    { name: 'Sass', icon: 'sass' },
    { name: 'Node.js', icon: 'node-js' },
    { name: 'Python', icon: 'python' },
    { name: 'Java', icon: 'java' },
    { name: 'PHP', icon: 'php' },
    { name: 'WordPress', icon: 'wordpress' },
    { name: 'Bootstrap', icon: 'bootstrap' },
    { name: 'MongoDB', icon: 'mongodb' },
    { name: 'MySQL', icon: 'mysql' },
    { name: 'AWS', icon: 'aws' },
    { name: 'Docker', icon: 'docker' },
    { name: 'Kubernetes', icon: 'kubernetes' },
    { name: 'Linux', icon: 'linux' },
    { name: 'Windows', icon: 'windows' },
    { name: 'Apple', icon: 'apple' }
  ];

  const socialIcons = [
    { name: 'GitHub', icon: 'github' },
    { name: 'GitLab', icon: 'gitlab' },
    { name: 'Bitbucket', icon: 'bitbucket' },
    { name: 'npm', icon: 'npm' },
    { name: 'LinkedIn', icon: 'linkedin' },
    { name: 'Medium', icon: 'medium' },
    { name: 'CodePen', icon: 'codepen' },
    { name: 'Stack Overflow', icon: 'stack-overflow' },
    { name: 'Twitter', icon: 'twitter' },
    { name: 'Facebook', icon: 'facebook' },
    { name: 'Instagram', icon: 'instagram' },
    { name: 'YouTube', icon: 'youtube' },
    { name: 'Website', icon: 'globe' },
    { name: 'Demo', icon: 'play' },
    { name: 'Download', icon: 'download' }
  ];

  const getIconForTech = (icon) => {
    switch(icon) {
      case 'react': return faReact;
      case 'angular': return faAngular;
      case 'vuejs': return faVuejs;
      case 'js': return faJs;
      case 'html5': return faHtml5;
      case 'css3-alt': return faCss3Alt;
      case 'sass': return faSass;
      case 'node-js': return faNodeJs;
      case 'python': return faPython;
      case 'java': return faJava;
      case 'php': return faPhp;
      case 'wordpress': return faWordpress;
      case 'bootstrap': return faBootstrap;
      case 'linux': return faLinux;
      case 'windows': return faWindows;
      case 'apple': return faApple;
      default: return faPlus;
    }
  };

  const getIconForSocial = (icon) => {
    switch(icon) {
      case 'github': return faGithub;
      case 'gitlab': return faGitlab;
      case 'bitbucket': return faBitbucket;
      case 'npm': return faNpm;
      case 'linkedin': return faLinkedin;
      case 'medium': return faMedium;
      case 'codepen': return faCodepen;
      case 'stack-overflow': return faStackOverflow;
      case 'twitter': return faTwitter;
      case 'facebook': return faFacebook;
      case 'instagram': return faInstagram;
      case 'youtube': return faYoutube;
      case 'globe': return faGlobe;
      case 'play': return faPlay;
      case 'download': return faDownload;
      default: return faPlus;
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-semibold text-white">Projeler</h2>
        {!editingProject && !newProject && (
          <button
            onClick={handleNew}
            className="inline-flex items-center px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700"
          >
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            Yeni Proje
          </button>
        )}
      </div>

      {(editingProject !== null || newProject) && (
        <form onSubmit={handleSubmit} className="bg-gray-800 p-6 rounded-lg mb-6 border border-gray-700">
          <h3 className="text-lg font-medium mb-4 text-white">
            {newProject ? 'Yeni Proje Ekle' : 'Projeyi Düzenle'}
          </h3>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div>
              <label className="block text-sm font-medium text-gray-200 mb-1">
                Başlık (TR)
              </label>
              <input
                type="text"
                name="title.tr"
                value={formData.title.tr}
                onChange={handleChange}
                className="w-full p-2 border border-gray-600 rounded-md bg-gray-700 text-white"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-200 mb-1">
                Başlık (EN)
              </label>
              <input
                type="text"
                name="title.en"
                value={formData.title.en}
                onChange={handleChange}
                className="w-full p-2 border border-gray-600 rounded-md bg-gray-700 text-white"
              />
            </div>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div>
              <label className="block text-sm font-medium text-gray-200 mb-1">
                Açıklama (TR)
              </label>
              <textarea
                name="text.tr"
                value={formData.text.tr}
                onChange={handleChange}
                rows="3"
                className="w-full p-2 border border-gray-600 rounded-md bg-gray-700 text-white"
              ></textarea>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-200 mb-1">
                Açıklama (EN)
              </label>
              <textarea
                name="text.en"
                value={formData.text.en}
                onChange={handleChange}
                rows="3"
                className="w-full p-2 border border-gray-600 rounded-md bg-gray-700 text-white"
              ></textarea>
            </div>
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-200 mb-1">
              Konum
            </label>
            <div className="flex space-x-4">
              <label className="inline-flex items-center text-white">
                <input
                  type="radio"
                  name="positionOption"
                  value="start"
                  checked={formData.positionOption === 'start'}
                  onChange={(e) => setFormData({
                    ...formData,
                    positionOption: e.target.value
                  })}
                  className="mr-2"
                />
                En başa ekle (Sıra: 1)
              </label>
              <label className="inline-flex items-center text-white">
                <input
                  type="radio"
                  name="positionOption"
                  value="end"
                  checked={formData.positionOption === 'end'}
                  onChange={(e) => setFormData({
                    ...formData,
                    positionOption: e.target.value
                  })}
                  className="mr-2"
                />
                En sona ekle
              </label>
            </div>
          </div>

          {!newProject && (
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-200 mb-1">
                Sıra Numarası
              </label>
              <input
                type="number"
                name="order"
                value={formData.order}
                onChange={handleChange}
                className="w-full p-2 border border-gray-600 rounded-md bg-gray-700 text-white"
              />
            </div>
          )}
          
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-200 mb-2">
              Resim URL'leri
            </label>
            {formData.img_Url.map((url, index) => (
              <div key={index} className="flex items-center mb-2">
                <input
                  type="text"
                  value={url}
                  onChange={(e) => handleArrayChange('img_Url', index, e.target.value)}
                  className="flex-grow p-2 border border-gray-600 rounded-md bg-gray-700 text-white mr-2"
                />
                <button
                  type="button"
                  onClick={() => handleArrayChange('img_Url', index, null)}
                  className="p-2 text-red-600 hover:text-red-800"
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={() => handleArrayChange('img_Url', formData.img_Url.length, '')}
              className="mt-2 px-3 py-1 text-sm bg-blue-100 text-blue-700 rounded-md hover:bg-blue-200"
            >
              + URL Ekle
            </button>
          </div>
          
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-200 mb-2">
              Üst Bağlantılar (Teknolojiler)
            </label>
            {formData.top_Link.map((link, index) => (
              <div key={index} className="flex items-center mb-2">
                <div className="relative w-1/3 mr-2">
                  <input
                    type="text"
                    placeholder="İkon Adı"
                    value={link.img_name || ''}
                    onChange={(e) => handleLinkChange('top_Link', index, 'img_name', e.target.value)}
                    className="w-full p-2 border border-gray-600 rounded-md bg-gray-700 text-white"
                  />
                  <div className="absolute right-2 top-2">
                    <div className="relative">
                      <button
                        type="button"
                        onClick={() => document.getElementById(`tech-dropdown-${index}`).classList.toggle('hidden')}
                        className="text-gray-300 hover:text-white"
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </button>
                      <div id={`tech-dropdown-${index}`} className="hidden absolute z-10 right-0 mt-2 w-56 rounded-md shadow-lg bg-gray-800 border border-gray-700 max-h-60 overflow-y-auto">
                        <div className="py-1 grid grid-cols-2 gap-1">
                          {technologyIcons.map((tech) => (
                            <button
                              key={tech.icon}
                              type="button"
                              onClick={() => {
                                handleLinkChange('top_Link', index, 'img_name', tech.icon);
                                document.getElementById(`tech-dropdown-${index}`).classList.add('hidden');
                              }}
                              className="px-4 py-2 text-sm text-gray-300 hover:bg-gray-700 flex items-center"
                            >
                              <FontAwesomeIcon icon={getIconForTech(tech.icon)} className="mr-2" />
                              {tech.name}
                            </button>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <input
                  type="text"
                  placeholder="Bağlantı"
                  value={link.link || ''}
                  onChange={(e) => handleLinkChange('top_Link', index, 'link', e.target.value)}
                  className="flex-grow p-2 border border-gray-600 rounded-md bg-gray-700 text-white mr-2"
                />
                <button
                  type="button"
                  onClick={() => handleArrayChange('top_Link', index, null)}
                  className="p-2 text-red-600 hover:text-red-800"
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={() => handleArrayChange('top_Link', formData.top_Link.length, {img_name: '', link: ''})}
              className="mt-2 px-3 py-1 text-sm bg-blue-100 text-blue-700 rounded-md hover:bg-blue-200"
            >
              + Bağlantı Ekle
            </button>
          </div>
          
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-200 mb-2">
              Alt Bağlantılar (GitHub vb.)
            </label>
            {formData.bottom_Link.map((link, index) => (
              <div key={index} className="flex items-center mb-2">
                <div className="relative w-1/3 mr-2">
                  <input
                    type="text"
                    placeholder="İkon Adı"
                    value={link.img_name || ''}
                    onChange={(e) => handleLinkChange('bottom_Link', index, 'img_name', e.target.value)}
                    className="w-full p-2 border border-gray-600 rounded-md bg-gray-700 text-white"
                  />
                  <div className="absolute right-2 top-2">
                    <div className="relative">
                      <button
                        type="button"
                        onClick={() => document.getElementById(`social-dropdown-${index}`).classList.toggle('hidden')}
                        className="text-gray-300 hover:text-white"
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </button>
                      <div id={`social-dropdown-${index}`} className="hidden absolute z-10 right-0 mt-2 w-56 rounded-md shadow-lg bg-gray-800 border border-gray-700 max-h-60 overflow-y-auto">
                        <div className="py-1 grid grid-cols-2 gap-1">
                          {socialIcons.map((social) => (
                            <button
                              key={social.icon}
                              type="button"
                              onClick={() => {
                                handleLinkChange('bottom_Link', index, 'img_name', social.icon);
                                document.getElementById(`social-dropdown-${index}`).classList.add('hidden');
                              }}
                              className="px-4 py-2 text-sm text-gray-300 hover:bg-gray-700 flex items-center"
                            >
                              <FontAwesomeIcon icon={getIconForSocial(social.icon)} className="mr-2" />
                              {social.name}
                            </button>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <input
                  type="text"
                  placeholder="Bağlantı"
                  value={link.link || ''}
                  onChange={(e) => handleLinkChange('bottom_Link', index, 'link', e.target.value)}
                  className="flex-grow p-2 border border-gray-600 rounded-md bg-gray-700 text-white mr-2"
                />
                <button
                  type="button"
                  onClick={() => handleArrayChange('bottom_Link', index, null)}
                  className="p-2 text-red-600 hover:text-red-800"
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={() => handleArrayChange('bottom_Link', formData.bottom_Link.length, {img_name: '', link: ''})}
              className="mt-2 px-3 py-1 text-sm bg-blue-100 text-blue-700 rounded-md hover:bg-blue-200"
            >
              + Bağlantı Ekle
            </button>
          </div>
          
          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={handleCancel}
              className="px-4 py-2 bg-gray-700 text-gray-200 rounded-md hover:bg-gray-600"
            >
              İptal
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
            >
              <FontAwesomeIcon icon={faSave} className="mr-2" />
              Kaydet
            </button>
          </div>
        </form>
      )}

      <div className="overflow-x-auto">
        <table className="min-w-full bg-gray-800">
          <thead className="bg-gray-700">
            <tr>
              <th className="py-3 px-4 text-left text-sm font-medium text-gray-200 uppercase tracking-wider">Sıra</th>
              <th className="py-3 px-4 text-left text-sm font-medium text-gray-200 uppercase tracking-wider">Başlık</th>
              <th className="py-3 px-4 text-left text-sm font-medium text-gray-200 uppercase tracking-wider">Açıklama</th>
              <th className="py-3 px-4 text-left text-sm font-medium text-gray-200 uppercase tracking-wider">Resimler</th>
              <th className="py-3 px-4 text-left text-sm font-medium text-gray-200 uppercase tracking-wider">Sıralama</th>
              <th className="py-3 px-4 text-left text-sm font-medium text-gray-200 uppercase tracking-wider">İşlemler</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-700">
            {sortedProjects.map((project) => (
              <tr key={project.id} className="hover:bg-gray-700">
                <td className="py-3 px-4 text-sm">{project.order}</td>
                <td className="py-3 px-4 text-sm">{project.title?.tr || ''}</td>
                <td className="py-3 px-4 text-sm">{project.text?.tr ? (project.text.tr.length > 50 ? project.text.tr.substring(0, 50) + '...' : project.text.tr) : ''}</td>
                <td className="py-3 px-4 text-sm">{project.img_Url && project.img_Url.length ? project.img_Url.length : 0} resim</td>
                <td className="py-3 px-4 text-sm space-x-2">
                  <button
                    onClick={() => handleMoveProject(project.id, 'up')}
                    className="inline-flex items-center p-1.5 bg-gray-600 text-white rounded-md hover:bg-gray-500 mr-1"
                    disabled={sortedProjects.indexOf(project) === 0}
                  >
                    <FontAwesomeIcon icon={faArrowUp} />
                  </button>
                  <button
                    onClick={() => handleMoveProject(project.id, 'down')}
                    className="inline-flex items-center p-1.5 bg-gray-600 text-white rounded-md hover:bg-gray-500"
                    disabled={sortedProjects.indexOf(project) === sortedProjects.length - 1}
                  >
                    <FontAwesomeIcon icon={faArrowDown} />
                  </button>
                </td>
                <td className="py-3 px-4 text-sm space-x-2">
                  <button
                    onClick={() => handleEdit(project)}
                    className="inline-flex items-center p-1.5 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </button>
                  <button
                    onClick={() => handleDelete(project.id)}
                    className="inline-flex items-center p-1.5 bg-red-600 text-white rounded-md hover:bg-red-700"
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </td>
              </tr>
            ))}
            {(!sortedProjects || sortedProjects.length === 0) && (
              <tr>
                <td colSpan="6" className="py-4 text-center text-gray-500">
                  Proje bulunamadı.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProjectsTab; 