import React, { useState } from 'react';
import { addExperience, updateExperience, deleteExperience } from '../../service/service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faEdit, 
  faTrash, 
  faPlus, 
  faSave, 
  faTimes, 
  faLaptopCode, 
  faServer, 
  faCode,
  faDatabase,
  faCloud,
  faBriefcase,
  faGraduationCap,
  faGlobe,
  faMobile,
  faFileCode,
  faCogs,
  faNetworkWired,
  faTerminal,
  faDesktop,
  faTools
} from '@fortawesome/free-solid-svg-icons';

const emptyExperience = {
  title: { en: '', tr: '' },
  company: { en: '', tr: '' },
  description: { en: '', tr: '' },
  date: '',
  icon: '',
  skills: []
};

const ExperiencesTab = ({ experiences }) => {
  const [editingExperience, setEditingExperience] = useState(null);
  const [newExperience, setNewExperience] = useState(null);
  const [formData, setFormData] = useState({ ...emptyExperience });

  const handleEdit = (experience, index) => {
    const experienceCopy = { ...experience };
    
    if (typeof experienceCopy.date === 'object' && experienceCopy.date !== null) {
      experienceCopy.date = experienceCopy.date.tr || experienceCopy.date.en || '';
    }
    
    setEditingExperience(index);
    setFormData(experienceCopy);
    setNewExperience(null);
  };

  const handleNew = () => {
    setNewExperience(true);
    setEditingExperience(null);
    setFormData({ ...emptyExperience });
  };

  const handleCancel = () => {
    setEditingExperience(null);
    setNewExperience(null);
  };

  const handleDelete = async (index) => {
    if (window.confirm('Bu deneyimi silmek istediğinizden emin misiniz?')) {
      try {
        await deleteExperience(index);
      } catch (error) {
        console.error('Silme hatası:', error);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.includes('.')) {
      const [field, lang] = name.split('.');
      setFormData({
        ...formData,
        [field]: {
          ...formData[field],
          [lang]: value
        }
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const handleSkillChange = (index, value) => {
    const newSkills = [...formData.skills];
    if (value === null) {
      // Sil
      newSkills.splice(index, 1);
    } else if (index >= newSkills.length) {
      // Ekle
      newSkills.push(value);
    } else {
      // Güncelle
      newSkills[index] = value;
    }
    setFormData({
      ...formData,
      skills: newSkills
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Tarih tek giriş olduğu için hem TR hem EN için aynı değeri ata
      const formDataToSubmit = {
        ...formData,
        date: {
          en: formData.date,
          tr: formData.date
        }
      };
      
      if (newExperience) {
        await addExperience(formDataToSubmit);
      } else if (editingExperience !== null) {
        await updateExperience(editingExperience, formDataToSubmit);
      }
      setEditingExperience(null);
      setNewExperience(null);
    } catch (error) {
      console.error('Kaydetme hatası:', error);
    }
  };

  const getIcon = (iconName) => {
    switch (iconName) {
      case 'laptop-code':
        return faLaptopCode;
      case 'server':
        return faServer;
      case 'code':
        return faCode;
      case 'database':
        return faDatabase;
      case 'cloud':
        return faCloud;
      case 'briefcase':
        return faBriefcase;
      case 'graduation-cap':
        return faGraduationCap;
      case 'globe':
        return faGlobe;
      case 'mobile':
        return faMobile;
      case 'file-code':
        return faFileCode;
      case 'cogs':
        return faCogs;
      case 'network-wired':
        return faNetworkWired;
      case 'terminal':
        return faTerminal;
      case 'desktop':
        return faDesktop;
      case 'tools':
        return faTools;
      default:
        return faCode;
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-semibold text-white">Deneyimler</h2>
        {!editingExperience && !newExperience && (
          <button
            onClick={handleNew}
            className="inline-flex items-center px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700"
          >
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            Yeni Deneyim
          </button>
        )}
      </div>

      {(editingExperience !== null || newExperience) && (
        <form onSubmit={handleSubmit} className="bg-gray-800 p-6 rounded-lg mb-6 border border-gray-700 shadow-lg">
          <h3 className="text-lg font-medium mb-6 text-white border-b border-gray-700 pb-3">
            {newExperience ? 'Yeni Deneyim Ekle' : 'Deneyimi Düzenle'}
          </h3>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div>
              <label className="block text-sm font-medium text-gray-200 mb-2">
                Şirket Adı (TR)
              </label>
              <input
                type="text"
                name="company.tr"
                value={formData.company.tr}
                onChange={handleChange}
                className="w-full p-3 border border-gray-600 rounded-md bg-gray-700 text-white focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-colors"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-200 mb-2">
                Şirket Adı (EN)
              </label>
              <input
                type="text"
                name="company.en"
                value={formData.company.en}
                onChange={handleChange}
                className="w-full p-3 border border-gray-600 rounded-md bg-gray-700 text-white focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-colors"
              />
            </div>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div>
              <label className="block text-sm font-medium text-gray-200 mb-2">
                Görev (TR)
              </label>
              <input
                type="text"
                name="title.tr"
                value={formData.title.tr}
                onChange={handleChange}
                className="w-full p-3 border border-gray-600 rounded-md bg-gray-700 text-white focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-colors"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-200 mb-2">
                Görev (EN)
              </label>
              <input
                type="text"
                name="title.en"
                value={formData.title.en}
                onChange={handleChange}
                className="w-full p-3 border border-gray-600 rounded-md bg-gray-700 text-white focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-colors"
              />
            </div>
          </div>
          
          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-200 mb-2">
              Tarih
            </label>
            <input
              type="text"
              name="date"
              value={formData.date}
              placeholder="Örn: 2020 - 2023"
              onChange={handleChange}
              className="w-full p-3 border border-gray-600 rounded-md bg-gray-700 text-white focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-colors"
            />
            <p className="mt-2 text-sm text-gray-400">Girilen tarih hem Türkçe hem İngilizce için kullanılacaktır.</p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div>
              <label className="block text-sm font-medium text-gray-200 mb-2">
                Açıklama (TR)
              </label>
              <textarea
                name="description.tr"
                value={formData.description.tr}
                onChange={handleChange}
                rows="4"
                className="w-full p-3 border border-gray-600 rounded-md bg-gray-700 text-white focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-colors resize-none"
              ></textarea>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-200 mb-2">
                Açıklama (EN)
              </label>
              <textarea
                name="description.en"
                value={formData.description.en}
                onChange={handleChange}
                rows="4"
                className="w-full p-3 border border-gray-600 rounded-md bg-gray-700 text-white focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-colors resize-none"
              ></textarea>
            </div>
          </div>
          
          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-200 mb-2">
              İkon (FontAwesome)
            </label>
            <div className="flex flex-wrap gap-3 mb-2">
              <button
                type="button"
                onClick={() => setFormData({...formData, icon: 'laptop-code'})}
                className={`p-3 rounded-md border ${formData.icon === 'laptop-code' ? 'border-blue-500 bg-blue-900/30' : 'border-gray-600 bg-gray-700'}`}
                title="Laptop Code"
              >
                <FontAwesomeIcon icon={faLaptopCode} className="text-white text-xl" />
              </button>
              <button
                type="button"
                onClick={() => setFormData({...formData, icon: 'server'})}
                className={`p-3 rounded-md border ${formData.icon === 'server' ? 'border-blue-500 bg-blue-900/30' : 'border-gray-600 bg-gray-700'}`}
                title="Server"
              >
                <FontAwesomeIcon icon={faServer} className="text-white text-xl" />
              </button>
              <button
                type="button"
                onClick={() => setFormData({...formData, icon: 'code'})}
                className={`p-3 rounded-md border ${formData.icon === 'code' ? 'border-blue-500 bg-blue-900/30' : 'border-gray-600 bg-gray-700'}`}
                title="Code"
              >
                <FontAwesomeIcon icon={faCode} className="text-white text-xl" />
              </button>
              <button
                type="button"
                onClick={() => setFormData({...formData, icon: 'database'})}
                className={`p-3 rounded-md border ${formData.icon === 'database' ? 'border-blue-500 bg-blue-900/30' : 'border-gray-600 bg-gray-700'}`}
                title="Database"
              >
                <FontAwesomeIcon icon={faDatabase} className="text-white text-xl" />
              </button>
              <button
                type="button"
                onClick={() => setFormData({...formData, icon: 'cloud'})}
                className={`p-3 rounded-md border ${formData.icon === 'cloud' ? 'border-blue-500 bg-blue-900/30' : 'border-gray-600 bg-gray-700'}`}
                title="Cloud"
              >
                <FontAwesomeIcon icon={faCloud} className="text-white text-xl" />
              </button>
              <button
                type="button"
                onClick={() => setFormData({...formData, icon: 'briefcase'})}
                className={`p-3 rounded-md border ${formData.icon === 'briefcase' ? 'border-blue-500 bg-blue-900/30' : 'border-gray-600 bg-gray-700'}`}
                title="Briefcase"
              >
                <FontAwesomeIcon icon={faBriefcase} className="text-white text-xl" />
              </button>
              <button
                type="button"
                onClick={() => setFormData({...formData, icon: 'graduation-cap'})}
                className={`p-3 rounded-md border ${formData.icon === 'graduation-cap' ? 'border-blue-500 bg-blue-900/30' : 'border-gray-600 bg-gray-700'}`}
                title="Graduation Cap"
              >
                <FontAwesomeIcon icon={faGraduationCap} className="text-white text-xl" />
              </button>
              <button
                type="button"
                onClick={() => setFormData({...formData, icon: 'globe'})}
                className={`p-3 rounded-md border ${formData.icon === 'globe' ? 'border-blue-500 bg-blue-900/30' : 'border-gray-600 bg-gray-700'}`}
                title="Globe"
              >
                <FontAwesomeIcon icon={faGlobe} className="text-white text-xl" />
              </button>
              <button
                type="button"
                onClick={() => setFormData({...formData, icon: 'mobile'})}
                className={`p-3 rounded-md border ${formData.icon === 'mobile' ? 'border-blue-500 bg-blue-900/30' : 'border-gray-600 bg-gray-700'}`}
                title="Mobile"
              >
                <FontAwesomeIcon icon={faMobile} className="text-white text-xl" />
              </button>
              <button
                type="button"
                onClick={() => setFormData({...formData, icon: 'file-code'})}
                className={`p-3 rounded-md border ${formData.icon === 'file-code' ? 'border-blue-500 bg-blue-900/30' : 'border-gray-600 bg-gray-700'}`}
                title="File Code"
              >
                <FontAwesomeIcon icon={faFileCode} className="text-white text-xl" />
              </button>
              <button
                type="button"
                onClick={() => setFormData({...formData, icon: 'cogs'})}
                className={`p-3 rounded-md border ${formData.icon === 'cogs' ? 'border-blue-500 bg-blue-900/30' : 'border-gray-600 bg-gray-700'}`}
                title="Cogs"
              >
                <FontAwesomeIcon icon={faCogs} className="text-white text-xl" />
              </button>
              <button
                type="button"
                onClick={() => setFormData({...formData, icon: 'network-wired'})}
                className={`p-3 rounded-md border ${formData.icon === 'network-wired' ? 'border-blue-500 bg-blue-900/30' : 'border-gray-600 bg-gray-700'}`}
                title="Network Wired"
              >
                <FontAwesomeIcon icon={faNetworkWired} className="text-white text-xl" />
              </button>
              <button
                type="button"
                onClick={() => setFormData({...formData, icon: 'terminal'})}
                className={`p-3 rounded-md border ${formData.icon === 'terminal' ? 'border-blue-500 bg-blue-900/30' : 'border-gray-600 bg-gray-700'}`}
                title="Terminal"
              >
                <FontAwesomeIcon icon={faTerminal} className="text-white text-xl" />
              </button>
              <button
                type="button"
                onClick={() => setFormData({...formData, icon: 'desktop'})}
                className={`p-3 rounded-md border ${formData.icon === 'desktop' ? 'border-blue-500 bg-blue-900/30' : 'border-gray-600 bg-gray-700'}`}
                title="Desktop"
              >
                <FontAwesomeIcon icon={faDesktop} className="text-white text-xl" />
              </button>
              <button
                type="button"
                onClick={() => setFormData({...formData, icon: 'tools'})}
                className={`p-3 rounded-md border ${formData.icon === 'tools' ? 'border-blue-500 bg-blue-900/30' : 'border-gray-600 bg-gray-700'}`}
                title="Tools"
              >
                <FontAwesomeIcon icon={faTools} className="text-white text-xl" />
              </button>
            </div>
            <input
              type="text"
              name="icon"
              value={formData.icon}
              onChange={handleChange}
              className="w-full mt-2 p-3 border border-gray-600 rounded-md bg-gray-700 text-white focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-colors"
              placeholder="laptop-code, server, code, database, cloud, briefcase..."
            />
          </div>
          
          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-200 mb-2">
              Beceriler
            </label>
            <div className="space-y-2 max-h-60 overflow-y-auto p-2 border border-gray-600 rounded-md bg-gray-800 mb-2">
              {formData.skills.map((skill, index) => (
                <div key={index} className="flex items-center bg-gray-700 rounded-md overflow-hidden">
                  <input
                    type="text"
                    value={skill}
                    onChange={(e) => handleSkillChange(index, e.target.value)}
                    className="flex-grow p-3 border-none bg-transparent text-white focus:outline-none focus:ring-1 focus:ring-blue-500"
                  />
                  <button
                    type="button"
                    onClick={() => handleSkillChange(index, null)}
                    className="p-3 text-gray-300 hover:text-red-500 transition-colors"
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </div>
              ))}
              {formData.skills.length === 0 && (
                <div className="text-center p-3 text-gray-400">
                  Henüz beceri eklenmedi.
                </div>
              )}
            </div>
            <button
              type="button"
              onClick={() => handleSkillChange(formData.skills.length, '')}
              className="w-full px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors flex items-center justify-center"
            >
              <FontAwesomeIcon icon={faPlus} className="mr-2" />
              Beceri Ekle
            </button>
          </div>
          
          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={handleCancel}
              className="px-5 py-2.5 bg-gray-600 text-white rounded-md hover:bg-gray-700 transition-colors shadow-md"
            >
              İptal
            </button>
            <button
              type="submit"
              className="px-5 py-2.5 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors shadow-md flex items-center"
            >
              <FontAwesomeIcon icon={faSave} className="mr-2" />
              Kaydet
            </button>
          </div>
        </form>
      )}

      <div className="overflow-x-auto rounded-lg shadow-md border border-gray-700">
        <table className="min-w-full bg-gray-800">
          <thead className="bg-gray-700">
            <tr>
              <th className="py-3.5 px-4 text-left text-sm font-medium text-gray-200 uppercase tracking-wider">Şirket</th>
              <th className="py-3.5 px-4 text-left text-sm font-medium text-gray-200 uppercase tracking-wider">Pozisyon</th>
              <th className="py-3.5 px-4 text-left text-sm font-medium text-gray-200 uppercase tracking-wider">Tarih</th>
              <th className="py-3.5 px-4 text-left text-sm font-medium text-gray-200 uppercase tracking-wider">İşlemler</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-700">
            {experiences && Array.isArray(experiences) && Object.entries(experiences).reverse().map(([key, experience], index) => (
              <tr key={key} className="hover:bg-gray-700 transition-colors">
                <td className="py-4 px-4 text-sm text-white">{experience.company?.tr || ''}</td>
                <td className="py-4 px-4 text-sm text-white">{experience.title?.tr || ''}</td>
                <td className="py-4 px-4 text-sm text-white">{experience.date?.tr || (typeof experience.date === 'string' ? experience.date : '') || ''}</td>
                <td className="py-4 px-4 text-sm space-x-2">
                  <button
                    onClick={() => handleEdit(experience, key)}
                    className="inline-flex items-center p-1.5 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
                    title="Düzenle"
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </button>
                  <button
                    onClick={() => handleDelete(key)}
                    className="inline-flex items-center p-1.5 bg-red-600 text-white rounded-md hover:bg-red-700 transition-colors"
                    title="Sil"
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </td>
              </tr>
            ))}
            {(!experiences || !Array.isArray(experiences) || experiences.length === 0) && (
              <tr>
                <td colSpan="4" className="py-8 text-center text-gray-400">
                  Deneyim bulunamadı.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ExperiencesTab; 